import { lazy } from 'react';

const RckvergtungenHeader = lazy(() => import('./RckvergtungenHeader'));
const RckvergtungenTable = lazy(() => import('./RckvergtungenTable'));
const AddOrUpdateRckvergtungenModal = lazy(() => import('./AddOrUpdateRckvergtungenModal'));
const RckvergtungenTypeForm = lazy(() => import('./RckvergtungenTypeForm'));

export {
  RckvergtungenHeader,
  RckvergtungenTable,
  AddOrUpdateRckvergtungenModal,
  RckvergtungenTypeForm
};
