import React from 'react';
import { MessageOutlined } from '@ant-design/icons';
import Routes from '@app_routing/Routes';

const AppMenus = [
  {
    key: '2',
    icon: <MessageOutlined />,
    route: Routes.R_CKVERG_TUNGEN_ROUTE,
    menuName: 'Refund'
  }
];

export default AppMenus;
