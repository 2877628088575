import React, { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Space } from 'antd';
import { inject, observer } from 'mobx-react';

/**CORE IMPORTS */
import { ExactCard, ExactTitle } from '@core_common/components';

/**CORE IMPORTS */
import { FallBackLoaders } from '@core_common/components';
import { RckvergtungenTable, RckvergtungenHeader } from '@app_modules/Rckvergtungen/components';

function Rckvergtungen({ store }) {
  const { t } = useTranslation('common');
  const [form] = Form.useForm();

  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  useEffect(() => {
    console.log('user', store.login);
  }, []);
  return (
    <div>
      <Suspense fallback={FallBackLoaders.EmptyLoader}>
        <RckvergtungenHeader form={form} isDeleteVisible={isDeleteVisible} />
      </Suspense>

      <Suspense fallback={FallBackLoaders.EmptyLoader}>
        <RckvergtungenTable form={form} setIsDeleteVisible={setIsDeleteVisible} />
      </Suspense>
    </div>
  );
}

export default inject('store')(observer(Rckvergtungen));
