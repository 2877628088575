import { types } from 'mobx-state-tree';

const RckvergtungenModel = types
  .model('RckvergtungenModel', {
    wholesaler: types.optional(types.string, ''),
    id: types.optional(types.integer, 0),
    customer_id: types.optional(types.string, ''),
    user_id: types.optional(types.string, ''),
    brand: types.optional(types.string, ''),
    product: types.optional(types.string, ''),
    quantity: types.optional(types.number, 0),
    pharmacode: types.optional(types.string, ''),
    refund: types.optional(types.number, 0)
  })
  .views((self) => ({}))
  .actions((self) => ({}));

export default RckvergtungenModel;
