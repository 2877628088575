import {
  RckvergtungenState,
  RckvergtungenManagementUtilities
} from '@app_modules/Rckvergtungen/model';
import { types } from 'mobx-state-tree';

const AppStore = {
  ruckvergutungen: types.optional(RckvergtungenState, {}),
  RckvergtungenManagementUtilities: types.optional(RckvergtungenManagementUtilities, {})
};

export default AppStore;
